// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$gtw-green:         #76b72a;
$gtw-blue:			#00b4ec;
$gtw-grey:			#666666;
$gtw-light-grey:	#eeeeee;
$gtw-ghost-grey:	#f5f5f5;
$gtw-black:			#000000;
$gtw-white:			#ffffff;
