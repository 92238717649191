/*Typography*/

body, p{
	font-family: "nimbus-sans",sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	color: $gtw-grey;
}

h1, h2, h3, h4, h5, h6{
	font-family: "futura-pt",sans-serif;
	font-style: normal;
	font-weight: 700;
	text-transform: uppercase;
	color: $gtw-black;
}

h1{
	font-size: 3.5rem;
	margin-bottom: 2rem;
}

h2{
	margin-top: 50px;
	margin-bottom: 1.5rem;
}

h3{
	margin-top: 2.75rem;
	margin-bottom: 1.5rem;
}

p, .main a, .main li, .gtw-testimonials{
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

strong{
	color: $gtw-black;
}

/*Links*/

a{
	color: $gtw-green;
	transition: all ease 0.5s;
	text-decoration: none;
}

a:hover{
	opacity: 0.8;
	color: $gtw-green;
	transition: all ease 0.5s;
	text-decoration: none;
}