/*Wrappers*/

/*Page Hero*/

.gtw-page-hero{
	width: 100%;
	height: 600px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 60px;
}

.gtw-page-hero .container{
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.gtw-page-hero .page-header{
	text-align: center;
	color: $gtw-white;
}

.gtw-page-hero .page-header h1{
	color: $gtw-white;
}

.gtw-page-hero p{
	text-align: center;
	color: $gtw-white;
	max-width: 800px;
	margin: 0 auto;
}

/*GTW Page Sections*/

.gtw-page-section{
	margin-bottom: 50px;
	padding-bottom: 50px;
	border-bottom: 1px solid $gtw-light-grey;
}

.gtw-page-section:last-of-type{
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: none;
}

.gtw-page-section h2 a{
	color: $gtw-black;
	text-decoration: none;
}

.gtw-page-section h2 a:hover{
	color: $gtw-green;
}

.gtw-page-section h2:first-child{
	margin-top: 0;
}

.gtw-page-section h3:first-child{
	margin-top: 0;
}

.gtw-page-section-gallery{
	padding-bottom: 37.5px;
}

.gtw-page-section-single-product{
	margin-bottom: 40px;
}

/*GTW Team*/

.gtw-team-member{
	margin-bottom: 50px;
}

.gtw-team-member:last-child{
	margin-bottom: 0;
}

.gtw-team-member h3{
	margin-bottom: 0;
}

.gtw-team-member h4{
	color: $gtw-green;
	margin-bottom: 1.5rem;
}

/*Content*/

.gtw-wrapper{
	background: url(../images/gtw-bg.png);
	background-position: center top;
	background-repeat: repeat-x;
	padding: 0;
}

.gtw-wrapper-container{
	background: $gtw-white;
	padding: 75px;
}

.gtw-wrapper p:last-child{
	margin-bottom: 0;
}

.gtw-wrapper ul{
	padding-left: 20px;
}

.gtw-wrapper ul li{
	line-height: 1.3rem;
	margin-bottom: 0.75rem;
}

.gtw-wrapper ul li:last-child{
	margin-bottom: 0;
}

.gtw-wrapper-container ul:last-child{
	margin-bottom: 0;
}

.gtw-wrapper-content{
	max-width: 800px;
	margin: 0 auto;
}

/*White*/

.gtw-wrapper-white{
	background: $gtw-white;
	padding: 90px 75px;
}

.gtw-wrapper-white .container{
	max-width: 830px;
}

.gtw-wrapper-white p:last-child{
	margin-bottom: 0;
}

/*Green*/

.gtw-wrapper-green{
	background: $gtw-green;
	padding: 90px 75px;
	color: $gtw-white;
}

.gtw-wrapper-green .container{
	max-width: 830px;
}

.gtw-wrapper-green h4{
	color: $gtw-white;
	font-size: 36px;
	margin-bottom: 0;
}

.gtw-wrapper-green p{
	color: $gtw-white;
	margin: 1.25rem 0 1.5rem;
}

.gtw-wrapper-green a{
	color: $gtw-white;
}

.gtw-wrapper-green a:hover{
	color: $gtw-white;
	text-decoration: underline;
}

/*GTW Home*/

.gtw-home-slider-wrapper{
	width: 100%;
	height: 800px;
}

.gtw-home-slider{
	height: 800px;
}

.gtw-home-slider-slide{
	height: 800px;
	background-position: center center;
	background-size: cover;
}

.gtw-home-slider-slide .container{
	position: relative;
	top: 75%;
	transform: translateY(-75%);
}

.gtw-home-slider-slide-container{
	width: 50%;
	background-color: rgba(0,0,0,0.6);
	padding: 30px;
	color: $gtw-white;
}

.gtw-home-slider-title h3{
	color: $gtw-white;
	margin-top: 0;
	font-size: 36px;
	margin-bottom: 15px;
}

.gtw-home-slider-slide-container p{
	color: $gtw-white;
}

.gtw-home-slider-wrapper .owl-dots{
	position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
	padding-bottom: 24px;
}

.gtw-home-slider-wrapper .owl-dot{
	display: inline-block;
	background: $gtw-white;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	margin: 0 2.5px;
	opacity: 0.4;
}

.gtw-home-slider-wrapper .owl-dots .active{
	opacity: 1;
	background: $gtw-green;
}

.gtw-wrapper-home-intro{
	text-align: center;
}

.gtw-wrapper-home-intro h2{
	color: $gtw-white;
	margin-top: 0;
	font-size: 42px;
}

.gtw-wrapper-home-intro a{
	font-family: "futura-pt",sans-serif;
	font-style: normal;
	font-weight: 700;
	text-transform: uppercase;
}

.gtw-wrapper-home-products{
	text-align: center;
	color: $gtw-white;
	padding: 90px 0 75px;
}

.gtw-wrapper-home-products h3{
	margin-top: 0;
	color: $gtw-white;
	font-size: 42px;
}

.gtw-wrapper-home-products p{
	color: $gtw-white;
	max-width: 830px;
	margin: 0 auto;
	padding: 0 15px;
}

.gtw-wrapper-home-products .col-md-6, .gtw-wrapper-home-products .col-lg-4{
	margin: 15px 0;
}

.gtw-home-products{
	margin-top: 25px;
}

.gtw-home-products-panel-image{
	height: 220px;
	background: $gtw-light-grey;
	background-size: cover;
	background-position: center center;
}

.gtw-home-products-panel-title-container{
	background: $gtw-green;
	color: $gtw-white;
	text-align: left;
}

.gtw-home-products-panel-title{
	float: left;
	padding: 15px 20px;
	font-family: "futura-pt",sans-serif;
	font-style: normal;
	font-weight: 700;
	text-transform: uppercase;
}

.gtw-home-products-panel-arrow{
	float: right;
	padding: 15px 20px;
	border-left: 1px solid $gtw-white;
}

.gtw-home-products-panel-arrow:hover{
	background: rgba(255,255,255,0.25);
	transition: ease background 0.5s;
}

/*GTW Products*/

.gtw-products-image{
	height: 183px;
	background-size: cover;
	background-position: center center;
	background-color: $gtw-light-grey;
}

/*GTW Brochures and Downloads*/

.gtw-brochures-and-downloads a{
	text-decoration: none;
	color: $gtw-green;
}

.gtw-download{
	border: 1px solid $gtw-light-grey;
	padding: 30px;
}

.gtw-download i{
	margin-right: 7.5px;
}

.gtw-download img{
	width: 100%;
	max-width: 58px;
	height: auto;
}

.gtw-download h3{
	font-size: 20px;
	margin-bottom: 0.25rem;
}

/*GTW Lead Time*/

.gtw-lead-time{
	border-bottom: 1px solid $gtw-light-grey;
	margin-top: 65px;
	padding: 0 0 1.5rem;
}

.gtw-lead-time p{
	line-height: 1.35rem;
}

.gtw-lead-time strong{
	color: $gtw-black;
}

/*GTW Parent Link */

.gtw-parent-link{
	padding-top: 1.5rem;
	text-align: center;
}

/*GTW Gallery*/

.gtw-gallery img{
	width: 100%;
	height: auto;
	margin: 15px 0;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.10);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.10);
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.10);
}

/*GTW Map*/

.acf-map {
	width: 100%;
	height: 500px;
	background: $gtw-light-grey;
}

/* fixes potential theme css conflict */

.acf-map img {
   max-width: inherit !important;
}

/*GTW Contact*/

.gtw-wrapper-contact-us .gtw-wrapper-container{
	padding-bottom: 69px;
}

.gtw-wrapper-contact-us h2{
	margin-top: 0;
}

input, textarea{
	width: 90%;
	font-family: "nimbus-sans",sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	color: $gtw-grey;
	padding: 5px 7.5px;
	border: 1px solid #dddddd;
}

input[type=submit]{
	background: $gtw-green;
	width: 150px;
	height: 50px;
	border: none;
	outline: none;
	border-radius: 5px 5px 5px 5px;
	-moz-border-radius: 5px 5px 5px 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	font-family: "futura-pt",sans-serif;
	font-style: normal;
	font-weight: 700;
	text-transform: uppercase;
	color: $gtw-white;
	font-size: 16px;
	transition: ease opacity 0.5s;
}

input[type=submit]:hover{
	opacity: 0.8;
	transition: ease opacity 0.5s;
}

input[type=submit]:disabled{
	opacity: 0.5;
	cursor: not-allowed;
}

label{
	width: 100%;
}

label span{
	font-family: "futura-pt",sans-serif;
	font-style: normal;
	font-weight: 700;
	text-transform: uppercase;
	color: $gtw-black;
	font-size: 16px;
}

.wpcf7-form-control-wrap{
	margin-bottom: 30px;
}

input[type=checkbox], input[type=radio]{
	width: auto;
}

span.wpcf7-list-item{
	margin: 0;
}

.gtw-acceptance{
	font-size: 0.75rem;
	text-transform: none;
}

p.gtw-acceptance{
	line-height: 1.25;
}

/*Solidor Door Builder*/

.gtw-solidor-door-builder{
	border-top: 1px solid $gtw-light-grey;
	padding-bottom: 0;
}

.gtw-solidor-door-builder h4{
	margin: 0;
	font-size: 36px;
}

.gtw-solidor-door-builder p{
	margin: 1.25rem 0;	
}

.gtw-solidor-door-builder img{
	width: 100%;
	max-width: 384px;
	height: auto;
}

.gtw-solidor-door-builder-image{
	text-align: center;
}

.gtw-wrapper-solidor-door-builder{
	height: 930px;	
}

.gtw-wrapper-solidor-door-builder iframe{
	display: block;
}

.gtw-solidor-door-builder a:visited, .gtw-solidor-door-builder a:focus{
	color: $gtw-green;
	text-decoration: none;
}

/*Get in touch*/

.gtw-get-in-touch ul{
	list-style: none;
	padding: 0;
	margin: 0;
}

.gtw-get-in-touch ul li{
	margin-bottom: 10px;
	height: 42px;
	padding-top: 10.5px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1.2em;
}

.gtw-get-in-touch ul li:last-child{
	margin-bottom: 0;
}

.gtw-get-in-touch-tel-icon{
	background: url(../images/gtw-telephone-icon-white.png);
	background-repeat: no-repeat;
	background-size: contain;
	padding-left: 55px;
}

.gtw-get-in-touch-email-icon{
	background: url(../images/gtw-email-icon-white.png);
	background-repeat: no-repeat;
	background-size: contain;
	padding-left: 55px;
}

.gtw-get-in-touch-address-icon{
	background: url(../images/gtw-address-icon-white.png);
	background-repeat: no-repeat;
	background-size: contain;
	padding-left: 55px;
}

/*Testimonials*/

.gtw-testimonials{
	border-bottom: 5px solid $gtw-green;
}

.gtw-testimonials h4{
	margin: 0;
	font-size: 36px;
}

.gtw-testimonial{
	margin: 1.25rem 0 1.5rem;
	font-style: italic;
}

.gtw-testimonials strong{
	color: $gtw-green;
	text-transform: uppercase;
}

/*iUbenda*/
#iubenda_policy{font-family: 'nimbus-sans',sans-serif !important;}
#iubenda_policy h1, #iubenda_policy h2, #iubenda_policy h3, #iubenda_policy h4{font-family: 'futura-pt',sans-serif !important;}
#iubenda_policy .iub_content{padding: 0 !important;}
#iubenda_policy.iubenda_fluid_policy .iub_container{margin-bottom: 0 !important;}
#iubenda_policy .iub_content .iub_footer{padding-bottom: 0 !important;}