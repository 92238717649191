.gtw-header-top{
	padding: 30px 0;
}

.gtw-header-logo{
	float: left;
}

.gtw-header-nav{
	float: left;
	height: 70px;
	margin-left: 75px;
}

.gtw-header-nav #menu-primary-navigation{
	font-size: 0;
	line-height: 70px;
	padding: 0;
}

.gtw-header-nav #menu-primary-navigation li{
	display: inline-block;
	font-size: 16px;
	margin: 0 1rem;
	font-family: "futura-pt",sans-serif;
	font-style: normal;
	font-weight: 700;
	text-transform: uppercase;
}

.gtw-header-nav #menu-primary-navigation li:first-child{
	margin-left: 0;
}

.gtw-header-nav #menu-primary-navigation li:last-child{
	margin-right: 0;
}

.gtw-header-nav #menu-primary-navigation li a{
	color: $gtw-black;
	text-decoration: none;
	transition: color ease 0.25s;
}

.gtw-header-nav #menu-primary-navigation li a:hover{
	color: $gtw-green;
	transition: color ease 0.25s;
	opacity: 1;
}

.gtw-header-nav .nav .active a{
	color: $gtw-green !important;
}

.gtw-header-contact{
	float: right;
	height: 70px;
	line-height: 70px;
	font-family: "futura-pt",sans-serif;
	font-style: normal;
	font-weight: 700;
	text-transform: uppercase;
}

.gtw-header-contact ul{
	list-style: none;
	font-size: 0;
	padding: 0;
	margin: 0;
}

.gtw-header-contact ul li{
	display: inline-block;
	font-size: 16px;
	color: $gtw-black;
}

.gtw-header-contact-tel img{
	margin-right: 5px;
}

.gtw-header-contact-tel-text{
	color: $gtw-black;
}

.gtw-header-contact-email{
	margin: 0 0.5rem 0 23px;
}

.gtw-products-nav{
	background: $gtw-green;
	height: 70px;
}

.gtw-products-nav .gtw-nav-products{
	list-style: none;
	padding: 0;
	margin: 0;
}

.gtw-products-nav .gtw-nav-products li{
	display: block;
	float: left;
	width: 16.6666666667%;
	font-size: 16px;
	text-align: center;
	border-right: 1px solid $gtw-white;
	height: 70px;
	line-height: 70px;
	font-family: "futura-pt",sans-serif;
	font-style: normal;
	font-weight: 700;
	text-transform: uppercase;
}

.gtw-products-nav .gtw-nav-products li:first-child{
	border-left: 1px solid $gtw-white;
}

.gtw-products-nav .gtw-nav-products li a{
	color: $gtw-white;
	display: block;
	text-decoration: none;
	transition: background ease 0.5s;
}

.gtw-products-nav .gtw-nav-products li a:hover{
	background: rgba(255,255,255,0.2);
	transition: background ease 0.5s;
	opacity: 1;
}

/*Media Queries*/

@media screen and (max-width: 1199px) {
	
	.gtw-header-contact-email{
		margin: 0 0.5rem;
	}
	
	.gtw-header-contact ul li span{
		display: none;
	}
	
	.gtw-header-contact-tel-number{
		display: none;
		color: $gtw-green;
	}
	
	.gtw-header-nav{
		margin-left: 4rem;
	}
	
	.gtw-header-contact-tel img{
		margin-right: 0;
	}
}

/*Responsive Nav*/

.gtw-mobile-nav .container{
	padding: 0;
}

.nav-collapse{
	padding: 0;
	margin: 0 0 15px;
}

.nav-collapse li {
	padding: 0 15px;
}

.nav-collapse li a{
	border-top: 1px solid $gtw-light-grey;
	padding: 7.5px 0;
    font-family: futura-pt,sans-serif;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    color: $gtw-black;
    display: block;
}

.nav-collapse li a:hover{
	color: $gtw-green;
}

/*Tiny Nav*/

/* styles for desktop */
.tinynav { display: none }

/* styles for mobile */
@media screen and (max-width: 991px) {
    .tinynav {
	    display: block;
	    width: 100%;
	    background-color: #D4ECB6;
	    border: 3px solid #73A338;
	    height: 46px;
	    font-size: 16px;
	}
    #menu-products-navigation {
	    display: none
	}
	
	.gtw-mobile-menu-container{
		padding-top: 12px;
	}
}