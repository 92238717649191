.gtw-footer{
	background: $gtw-ghost-grey;
	padding: 90px 0;
	text-align: center;
}

.gtw-footer img{
	margin-bottom: 1.25rem;
}

.gtw-footer p:last-child{
	margin-bottom: 0;
}

.gtw-footer-logos{
	margin-bottom: 30px;
	text-align: center;
}

.gtw-footer-logo-image{
	width: 20%;
}

.gtw-footer-logo-image img{
	width: 100%;
	max-width: 250px;
	height: auto;
}

.gtw-footer-logos p{
	font-family: "futura-pt",sans-serif;
	font-style: normal;
	font-weight: 700;
	text-transform: uppercase;
	color: $gtw-black;
	margin-bottom: 30px;
}