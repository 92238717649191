@media (max-width: 1200px) {
	.gtw-wrapper .container{
		max-width: 100%;
	}
}

@media (max-width: 1199px) {
	.gtw-header-nav{
		margin-left: 103px;
	}
	
	.gtw-products-nav .gtw-nav-products li a{
		font-size: 14px;
	}	
}

@media (max-width: 992px) {
	.gtw-products-image{
		background-size: cover;
		margin-bottom: 30px;
	}
	
	.gtw-header-mob{
		float: right;
	}
	
	.gtw-nav-toggle{
		width: 48px;
		height: 48px;
		background: $gtw-green;
		display: block;
		outline: none;
		border: none;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		margin-top: 11px;
		color: $gtw-white;
		text-align: center;
		font-size: 24px;
		text-decoration: none;
		float: left;
		transition: ease opacity 0.5s;
		line-height: 48px;
	}
	
	.gtw-nav-toggle:before{
		font-family: FontAwesome;
		content: "\f0c9";
		color: $gtw-white;
	}
	
	.gtw-nav-toggle.active:before{
		font-family: FontAwesome;
		content: "\f00d";
		color: $gtw-white;
	}
	
	.gtw-nav-toggle:focus{
		outline: 0;
	}
	
	.gtw-nav-toggle:hover{
		opacity: 0.8;
		transition: ease opacity 0.5s;
		cursor: pointer;
	}
	
	.gtw-mob-email{
		width: 48px;
		height: 48px;
		background: $gtw-green;
		display: block;
		outline: none;
		border: none;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		margin-top: 11px;
		color: $gtw-white;
		text-align: center;
		font-size: 24px;
		text-decoration: none;
		float: left;
		margin-right: 10px;
		transition: ease opacity 0.5s;
	}
	
	.gtw-mob-email:before{
		font-family: FontAwesome;
		content: "\f0e0";		
	}
	
	.gtw-mob-email:focus{
		outline: 0;
	}
	
	.gtw-mob-email:hover{
		opacity: 0.8;
		transition: ease opacity 0.5s;
	}
	
	.gtw-mob-call{
		width: 48px;
		height: 48px;
		background: $gtw-green;
		display: block;
		outline: none;
		border: none;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		margin-top: 11px;
		color: $gtw-white;
		text-align: center;
		font-size: 24px;
		text-decoration: none;
		float: left;
		margin-right: 10px;
		transition: ease opacity 0.5s;
		line-height: 48px;
	}
	
	.gtw-mob-call:before{
		font-family: FontAwesome;
		content: "\f095";
		color: $gtw-white;		
	}
	
	.gtw-mob-call:focus{
		outline: 0;
	}
	
	.gtw-mob-call:hover{
		opacity: 0.8;
		transition: ease opacity 0.5s;
		cursor: pointer;
	}
}

@media (max-width: 991px){
	.gtw-header-nav{
		display: none;
	}
	
	.gtw-header-contact{
		display: none;
	}
	
	.gtw-download-wrapper{
		margin-bottom: 15px;
	}
	
	.gtw-download-wrapper:last-child{
		margin-bottom: 0;
	}
	
	.gtw-home-slider-wrapper{
		height: 600px;
	}
	
	.gtw-home-slider-slide{
		height: 600px;
	}
	
	.gtw-home-slider-slide .container{
		top: 85%;
	}
	
	.gtw-home-slider-slide-container{
		width: 100%;
	}
	
	.gtw-home-slider-wrapper .owl-dots{
		display: none;
	}
}

@media (min-width:992px){
	.gtw-mobile-nav{
		display: none;
	}
	
	.gtw-header-mob{
		display: none;
	}
}

@media (max-width: 768px) {
	input, textarea{
		width: 100%;
	}
	
	.gtw-wrapper-contact-us .col-md-8{
		margin-bottom: 30px;
	}
	
	input[type=submit]{
		margin-top: -10px;
	}
	
	.gtw-header-nav{
		display: none;
	}
	
	.gtw-header-top{
		padding: 15px 0;
	}
}

@media (max-width: 480px){
	body, p{
		font-size: 16px;
	}
	
	h1{
		font-size: 36px;
		line-height: 1em;
	}
	
	h2{
		font-size: 24px;
		margin-top: 30px;
	}
	
	h3{
		font-size: 20px;
	}
	
	.gtw-wrapper-home-intro h2, .gtw-wrapper-home-products h3{
		font-size: 36px;
	}
	
	.gtw-wrapper-home-intro h2{
		padding: 0 30px;
	}
	
	.gtw-team-member h4{
		font-size: 18px;
	}
	
	.gtw-wrapper-green, .gtw-wrapper-white, .gtw-footer{
		padding: 60px 30px;
	}
	
	.gtw-solidor-door-builder{
		padding: 60px 30px 0;
	}
	
	.gtw-wrapper-green h4, .gtw-testimonials h4, .gtw-solidor-door-builder h4{
		font-size: 30px;
	}
	
	.gtw-page-hero{
		height: 400px;
		padding: 0;
	}
	
	.gtw-page-hero p{
		padding: 0 15px;
	}
	
	.gtw-wrapper-container{
		padding: 45px 30px;
	}
	
	.gtw-page-section{
		padding-bottom: 30px;
		margin-bottom: 30px;
	}
	
	.gtw-page-section-single-product{
		margin-bottom: 27.5px;
	}
	
	.gtw-wrapper-contact-us .gtw-wrapper-container{
		padding-bottom: 60px;
	}
	
	.gtw-team-member{
		margin-bottom: 30px;
	}
	
	.acf-map{
		height: 300px;
	}
	
	label span{
		font-size: 14px;
	}
	
	.gtw-wrapper-home-intro, .gtw-wrapper-home-products{
		padding: 60px 0;
	}
	
	.gtw-page-section-products h2{
		margin-bottom: 12.5px;
	}
	
	.gtw-products-image{
		margin-bottom: 20px;
	}
	
	.nav-collapse li a{
		font-size: 14px;
	}
	
	.gtw-home-slider-wrapper{
		height: 400px;
	}
	
	.gtw-home-slider-slide{
		height: 400px;
	}
	
	.gtw-home-slider-slide .container{
		bottom: 0;
	}
	
	.gtw-home-slider-slide-container{
		padding: 15px;
	}
	
	.gtw-home-slider-title h3{
		font-size: 24px;
	}
	
	.gtw-home-slider-title a{
		font-size: 14px;
	}
	
	.gtw-home-slider-slide-container p{
		font-size: 14px;
		margin-bottom: 12.5px;
	}
}